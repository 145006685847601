import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { RestClient } from "./RestClient";
import { Grid, Container } from '@material-ui/core/';
import { Energy } from "./Energy";
import { Power } from "./Power";

const restClient = new RestClient();
const stepCount = 3;
const slideTime = 20;
const daily = 657;

interface Props {}

interface State {
	overview: any,
	details: any,
	step: number,
}

export class App extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

        this.state = {
			overview: null,
			details: null,
			step: 1,
		};

		this.init = this.init.bind(this);
		this.step = this.step.bind(this);
		this.init();
	}

	init() {
		setInterval(() => {
			this.step();
		}, slideTime * 1000);
	}

	step() {
		let {step} = this.state;

		this.setState({
			step: step === stepCount ? 1 : step + 1
		})
	}

	componentDidMount()
	{
        restClient.get('/overview').then((data) => {
			this.setState({
				overview: data.overview
			})
		});

		restClient.get('/details').then((data) => {
			this.setState({
				details: data.details
			})
        });
	}

	render () {
		const { step } = this.state;

		return (
			<Container>
				<Grid container spacing={1} onClick={this.step}>
					<Router>
						<Switch>
							<Route path={"/"} render={(props) =>
								<>
									<Progress now={step} total={stepCount}/>
									{step === 1 &&
										<Energy
											{...this.state}
											view={1}
										/>
									}
									{step === 2 &&
										<Power
											{...this.state}
											{...props}
											daily={daily}
										/>
									}
									{step === 3 &&
										<Energy
											{...this.state}
											view={2}
										/>
									}
								</>
							}/>
						</Switch>
					</Router>
				</Grid>
			</Container>
		);
	}
}

interface ProgressProps {
	now: number,
	total: number
}

class Progress extends React.Component<ProgressProps> {

	render() {
		const { now, total } = this.props;

	  	return (
			<>
			<Grid item xs={12} style={{textAlign: "center", padding: "0px", marginTop:"-12px"}}>
				{[...Array(total)].map((e, i) =>
					<div className={"progress " + ((i + 1) === now ? "active" : "")} key={i}/>
				)}
			</Grid>
			</>
		);
	}
}

export default App;