import React from "react";
import { Grid, CardContent, Card  } from '@material-ui/core';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CountUp from "react-countup";
import saunaIcon from './img/solar.svg';
import eaIcon from './img/house.svg';

interface Props {
	match: any;
	overview: any,
	details: any,
	daily: number
}

export class Power extends React.Component<Props> {

	render() {
		const { overview, details, daily } = this.props;

		// Extract data
		const power = overview ? overview.currentPower.power : 0;
		const lastDay = overview ? overview.lastDayData.energy : 0;
		const peak = overview ? details.peakPower : 0;

		const powerWatt = power/1000;
		const effect = powerWatt > 0 && peak > 0 ? Math.round(powerWatt/peak * 100) : 0;
		const consumption = Math.round(((lastDay / 1000) / daily) * 100);

		return (
			<>
				<PowerCard
					title="Effekt just nu"
					subtext={"av maximal effekt "}
					subnumber={Math.round(peak) + " kWh"}
					value={effect}
					center={powerWatt}
					icon={saunaIcon}
					unit="kW"
					color="rgb(64, 180, 99)"
				/>
				<PowerCard
					title="Intjänat idag"
					subtext={"av medelförbrukning "}
					subnumber={daily + " kWh"}
					value={consumption}
					center={consumption}
					icon={eaIcon}
					unit="%"
					color="rgb(64, 145, 180)"
				/>
            </>
		);
	}
}

interface CardProps {
	title: string,
	value: number,
	center: number,
	unit: string,
	color: string,
	subtext: string,
	subnumber: string,
	icon?: string
}

class PowerCard extends React.Component<CardProps> {

	render() {
		const { title, value, center, unit, color, subtext, icon, subnumber } = this.props;

	  	return (
			<Grid item xs>
				<Card>
					<CardContent style={{ textAlign:'center'}}>
						<span className="card-title">
							{title}
						</span>
						<div className="card-circular-container">
							<CircularProgressbarWithChildren
								value={value}
								strokeWidth={9}
								circleRatio={0.5}
								styles={
									buildStyles({
										pathColor: color, rotation: 0.75, backgroundColor: '#f0f0f0',
									})
								}
							>
								<span className="card-circular-number" style={{color: color}}>
									<CountUp end={center} /> <span style={{color: color}} className="card-circular-unit">{unit}</span>
								</span>

							</CircularProgressbarWithChildren>
						</div>
						<span className="card-subtitle-power">
							{icon && <img src={icon} className="card-circular-icon"/>}
							{subtext}
							<br/>
							{subnumber}
						</span>
					</CardContent>
				</Card>
			</Grid>
		);
	}
}
