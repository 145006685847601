import fetchJsonp from 'fetch-jsonp';

export  class RestClient {
    public baseUrl = "https://monitoringapi.solaredge.com/site/1260017"
    public key = "0WXH5KIQYICA7KJDW574D9E8P52OF8QV";

    public get(path: string): Promise<any> {
        return fetchJsonp(this.baseUrl + path + "?api_key=" + this.key, {timeout: 15000})
            .then((response: any) => {
                return response.json();
            });
    }

    public getMocked(response: any)
    {
        return new Promise<number>((resolve) => {
            resolve(response);
        });
    }
}
