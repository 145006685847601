import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import carIcon from './img/car.svg';
import showerIcon from './img/shower.svg';
import microwaveIcon from './img/microwave.svg';
import cookingIcon from './img/cooking.svg';
import washingIcon from './img/washing.svg';
import dryerIcon from './img/dryer.svg';
import trainIcon from './img/train.svg';
import saunaIcon from './img/heater.svg';
import eaIcon from './img/house.svg';
import CountUp from 'react-countup';

interface Equivalent {
	icon: string,
	factor: number,
	unit: string,
	max?: number,
	maxUnit?: string,
	text: string
}

const equivalents: Equivalent[] = [
	{
		icon: carIcon,
		factor: 0.625,
		unit: "mil",
		text: "elbilskörning"
	},
	{
		icon: showerIcon,
		factor: 0.227,
		unit: "st",
		text: "varma duschar"
	},
	{
		icon: eaIcon,
		factor: 0.000416666666,
		unit: "%",
		text: "av årsförbrukning"
	},
	{
		icon: trainIcon,
		factor: 0.01,
		unit: "mil",
		text: "tågtransport"
	},
	{
		icon: saunaIcon,
		factor: 0.1666,
		unit: "tim",
		text: "bastubad",
		max: 24,
		maxUnit: "dagar"
	},
	{
		icon: microwaveIcon,
		factor: 0.9,
		unit: "tim",
		text: "med microvågsugn",
		max: 168,
		maxUnit: "veckor"
	},
	{
		icon: cookingIcon,
		factor: 0.1333,
		unit: "tim",
		text: "matlagning spisplatta",
		max: 24,
		maxUnit: "dagar"
	},
	{
		icon: washingIcon,
		factor: 1.5,
		unit: "st",
		text: "tvättprogram"
	},
	{
		icon: dryerIcon,
		factor: 0.2,
		unit: "st",
		text: "torktumlingar"
	},
]

interface Props {
	overview: any,
	details: any,
	view: number
}

export class Energy extends React.Component<Props> {

	render() {
		const { overview, view } = this.props;

		let dayEnergy = overview ? overview.lastDayData.energy : "0";
		let monthEnergy = overview ? overview.lastMonthData.energy : "0";

		let yearEnergy = overview ? overview.lastYearData.energy : "0";
		let totalEnergy = overview ? overview.lifeTimeData.energy : "0";

		return (
			<>
				{view === 1 &&
					<>
						<EnergyCard
							title="Energi idag"
							number={dayEnergy}
							unit="kWh"
						/>
						<EnergyCard
							title={"Energi " + new Date().toLocaleString('sv-se', { month: "short" })}
							number={monthEnergy}
							unit="kWh"
						/>
					</>
				}
				{view === 2 &&
					<>
						<EnergyCard
							title={"Energi " + new Date().toLocaleString('sv-se', { year: "numeric" })}
							number={yearEnergy}
							unit="kWh"
						/>
						<EnergyCard
							title="Energi totalt"
							number={totalEnergy}
							unit="kWh"
						/>
					</>
				}
            </>
		);
	}
}

interface CardProps {
	title: string,
	number: string,
	unit: string,
}

class EnergyCard extends React.Component<CardProps> {

	render() {
		const { title, number, unit } = this.props;
		const energy = Math.round(parseFloat(number) / 1000);

		const equivalent = equivalents[Math.floor(Math.random()*equivalents.length)];

		let equivalentNumber = 0;
		let equivalentUnit = "";

		if (equivalent.max && equivalent.factor * energy > equivalent.max) {
			equivalentNumber = Math.round((equivalent.factor * energy) / equivalent.max);
			equivalentUnit = equivalent.maxUnit ? equivalent.maxUnit : equivalent.unit;
		}
		else {
			equivalentNumber = Math.round(equivalent.factor * energy);
			equivalentUnit = equivalent.unit;
		}

	  	return (
			<Grid item xs>
				<Card>
					<CardContent>
						<span className="card-title">
							{title}
						</span>
						<span className="card-number">
							<CountUp end={energy} /> <span className="card-unit">{unit}</span>
						</span>
						<span className="card-subtitle-unit">
							<img src={equivalent.icon} alt="" className="card-subtitle-icon"/>
							<CountUp end={equivalentNumber} formattingFn={(number: number) => number.toLocaleString()} /> {equivalentUnit}
						</span>
						<span className="card-subtitle">
							{equivalent.text}
						</span>
					</CardContent>
				</Card>
			</Grid>
		);
	}
}
